import React from "react";
import "./About.css";
import { UilPen } from "@iconscout/react-unicons";

const About = () => {
  return (
    <div className="about" id="About">
      <div className="a-words">
        <div className="text-container">
          <span>About</span>
          <span className=" me">
            {" "}
            Me <UilPen style={{ color: "var(--white)" }} />
          </span>
        </div>

        <span className="a-span">
          {" "}
          My journey as a developer has been bittersweet, and I wouldn't
          exchange it for anything else. a dedicated front-end developer with a
          profound passion for crafting immersive web experiences. from west
          africa,lagos. I bring a handful of experience to the table,
          specializing in transforming design concepts into responsive,
          user-friendly websites and web applications.
        </span>

        <span className="a-span">
          {" "}
          My expertise lies in harnessing the power of HTML, CSS, and JavaScript
          to create seamless and visually stunning interfaces. I thrive on
          challenges and solving complex problems to deliver outstanding
          results.
        </span>

        <span className="a-span">
          {" "}
          I also believe that effective communication is vital to successful
          collaboration, and I'm adept at working closely with cross-functional
          teams and contributing my skills to innovative projects, including
          designers and backend developers, to bring projects to life.
        </span>
      </div>
    </div>
  );
};

export default About;
