import React from "react";
import "./Footer.css";
import { UilTwitter } from "@iconscout/react-unicons";
import { UilGithub } from "@iconscout/react-unicons";
import { UilLinkedin } from "@iconscout/react-unicons";

const Footer = () => {
  return (
    <div className="footer">
      <div className="f-content">
        <span>samflex3124@gmail.com</span>
        <div className="f-icons">
          <a
            href="https://twitter.com/ola_degen"
            target="_blank"
            rel="noopener noreferrer"
          >
            <UilTwitter className="f-icons" />
          </a>

          <a
            href="https://github.com/samflexx31"
            target="_blank"
            rel="noopener noreferrer"
          >
            <UilGithub className="f-icons" />
          </a>

          <UilLinkedin className="f-icons" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
