import React from "react";
import "./Projects.css";
import { motion } from "framer-motion";
import { UilGithub } from "@iconscout/react-unicons";
import { UilGlobe } from "@iconscout/react-unicons";
import Rest from "../../Images/fast-food-i.png";
import RESTM from "../../Images/fast-food-m.png";
import GL from "../../Images/Groceries_app(iPad).png";
import GL2 from "../../Images/Groceries_app(iPhone 6_7_8).png";
import Blog from "../../Images/blog-ipad.png";
import Blog2 from "../../Images/blog-iphone.png";
import RE from "../../Images/real-estate-app.png";
import REM from "../../Images/real-estate-app-mobile.png";

const Projects = () => {
  const leftToRightVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const rightToLeftVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 10, x: 0, transition: { duration: 1 } },
  };

  return (
    <div className="projects" id="Projects">
      <header>Portfolio</header>
      {/* First Project */}

      <div className="both both-s big">
        {/* Left side */}
        <motion.div
          className="r-projects"
          variants={leftToRightVariants}
          initial="hidden"
          whileInView="visible"
        >
          <img
            src={RE}
            alt=""
            style={{ width: "600px", position: "relative" }}
          />
          <img
            src={REM}
            alt=""
            style={{
              width: "200px",
              position: "absolute",
              right: "1rem",
              marginTop: "14rem",
            }}
          />
        </motion.div>

        {/* Right side */}
        <motion.div
          className="l-projects"
          variants={rightToLeftVariants}
          initial="hidden"
          whileInView="visible"
        >
          <span>Homyz</span>
          <span>
          Built a user-friendly React-based real estate app. Streamlined property search with a clean interface and interactive features.
          </span>
          <div>
            <a
              href="https://github.com/samflexx31/Real-estate-app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <UilGithub className="p-icons" />
            </a>

            <a
              href="https://real-estate-app-3qm.pages.dev/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <UilGlobe className="p-icons" />
            </a>
          </div>
        </motion.div>
      </div>

      {/* second project */}

      <div className="both">
        {/* Left side */}
        <motion.div
          className="l-projects"
          variants={leftToRightVariants}
          initial="hidden"
          whileInView="visible"
        >
          <span>Taco Shop</span>
          <span>
            I designed a vibrant and user-friendly website for a fictional taco
            shop, bringing their mouthwatering menu and charming atmosphere to
            life online. Using HTML, CSS, and JavaScript, I crafted an immersive
            experience that allows customers to explore the menu, place orders,
            and learn about the restaurant's story.
          </span>
          <div>
            <UilGithub className="p-icons" />
            <UilGlobe className="p-icons" />
          </div>
        </motion.div>

        {/* Right side */}
        <motion.div
          className="r-projects"
          variants={rightToLeftVariants}
          initial="hidden"
          whileInView="visible"
        >
          <img src={Rest} alt="" style={{ width: "300px" }} />
          <img
            src={RESTM}
            alt=""
            style={{
              width: "120px",
              position: "absolute",
              right: "6rem",
              marginTop: "14rem",
            }}
          />
        </motion.div>
      </div>

      {/* third Project */}
      <div className="both both-s">
        {/* Left side */}
        <motion.div
          className="r-projects"
          variants={leftToRightVariants}
          initial="hidden"
          whileInView="visible"
        >
          <img src={GL} alt="" style={{ width: "300px" }} />
          <img
            src={GL2}
            alt=""
            style={{
              width: "100px",
              position: "absolute",
              right: "7rem",
              marginTop: "14rem",
            }}
          />
        </motion.div>

        {/* Right side */}
        <motion.div
          className="l-projects"
          variants={rightToLeftVariants}
          initial="hidden"
          whileInView="visible"
        >
          <span>Groceries List</span>
          <span>
            I developed a user-friendly Groceries app using React, designed to
            simplify the task of managing shopping lists. This dynamic
            application allows users to easily create, update, and organize
            their grocery items.
          </span>
          <div>
            <a
              href="https://github.com/samflexx31/Groceries_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <UilGithub className="p-icons" />
            </a>
            <UilGlobe className="p-icons" />
          </div>
        </motion.div>
      </div>

      {/* fourth Project */}
      <div className="both">
        {/* Left side */}
        <motion.div
          className="l-projects"
          variants={leftToRightVariants}
          initial="hidden"
          whileInView="visible"
        >
          <span>Personal Blog</span>
          <span>
            I created a personalized Blog App using React, offering a platform
            for sharing my thoughts, experiences, and insights.
          </span>
          <div>
            <a
              href="https://github.com/samflexx31/My-Blog-App"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <UilGithub className="p-icons" />{" "}
            </a>
            <UilGlobe className="p-icons" />
          </div>
        </motion.div>

        {/* Right side */}
        <motion.div
          className="r-projects"
          variants={rightToLeftVariants}
          initial="hidden"
          whileInView="visible"
        >
          <img src={Blog} alt="" style={{ width: "300px" }} />
          <img
            src={Blog2}
            alt=""
            style={{
              width: "100px",
              position: "absolute",
              right: "7rem",
              marginTop: "14rem",
            }}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default Projects;
