import React from "react";
import "./Skills.css";
import { motion } from "framer-motion";
import { UilJavaScript } from "@iconscout/react-unicons";
import { UilCss3Simple } from "@iconscout/react-unicons";
import { UilReact } from "@iconscout/react-unicons";
import { UilVuejs } from "@iconscout/react-unicons";
import Python from "../../Images/python.svg";

const Skills = () => {
  const listItems = [
    "HTML",
    "CSS",
    "JavaScript",
    "Python",
    "React",
    "Vue.js",
    "Tailwind",
  ];

  return (
    <div className="skills" id="Skills">
      {/* left side */}
      <div className="l-skills">
        <span>Skills</span>
        <span>
          I remain at the forefront of web development trends and technologies,
          consistently honing my skills in contemporary frameworks such as
          React, Vue.js, and Tailwind. Majorly, my expertise centers on
          programming languages, including Python, JavaScript, and CSS.
        </span>

        <div className="list">
          <ul>
            {listItems.map((item, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.5,
                  delay: index * 0.2,
                }}
              >
                {item}
              </motion.li>
            ))}
          </ul>
        </div>
      </div>

      {/* right side */}
      <div className="r-skills">
        <div className="s-icons">
          <motion.div
            className="circle"
            whileInView={{
              rotate: [0, 720],
            }}
            transition={{
              duration: 5,
              loop: Infinity,
              ease: "linear",
            }}
          >
            <UilJavaScript className="s-circle" />
            <UilVuejs className="s-circle" />
            <img src={Python} alt="" className="c-image" />
            <UilCss3Simple className="s-circle" />
            <UilReact className="s-circle" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
