import React from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { useState } from "react";

export const Contact = () => {
  const form = useRef();

  const [done, setDone] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_w4pm6lr",
        "template_kaespu7",
        form.current,
        "zrcMSYzsz8kHfMwnd"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
          setUserName("");
          setUserEmail("");
          setUserMessage("");
          setTimeout(() => {
            setDone(false);
          }, 3000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact" id="Contact">
      {/* left side */}
      <div className="l-contact">
        <span>Get in Touch</span>
        <span>Contact Me</span>
      </div>

      {/* right side */}

      <div className="r-contact">
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="user_name"
            className="user"
            placeholder="Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <input
            type="email"
            name="user_email"
            className="user"
            placeholder="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          <textarea
            className="user"
            name="message"
            placeholder="Message"
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
          />
          <input type="submit" value="send" className="button c-button" />
          <span
            className="submit-span"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              display: done ? "block" : "none",
            }}
          >
            Thanks for contacting me
          </span>
        </form>
      </div>
    </div>
  );
};
