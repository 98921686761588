import React from "react";
import "./Intro.css";
import { UilTwitter } from "@iconscout/react-unicons";
import { UilGithub } from "@iconscout/react-unicons";
import { UilLinkedin } from "@iconscout/react-unicons";
import Man from "../Images/ml.png";
import { motion } from "framer-motion";
import { useTypewriter } from "react-simple-typewriter";

const Intro = () => {
  const animationVariants = {
    hiddenLeft: { x: "-100%" },
    visibleLeft: { x: 0, transition: { duration: 3, type: "spring" } },
    hiddenRight: { x: "100%" },
    visibleRight: { x: 0, transition: { duration: 3, type: "spring" } },
  };

  const [text] = useTypewriter({
    words: ["Ola Tunji.", "Front-end Developer"],
    loop: true,
    typeSpeed: 30,
    deleteSpeed: 24,
    delaySpeed: 2000,
  });

  const handleClick = () => {
    window.location.href = "mailto:samflex3124@gmail.com";
  };

  return (
    <div className="intro">
      {/* left side */}
      <div className="l-intro">
        <div className="i-name">
          <span>yo! I'm</span>
          <span> {text} </span>
          <span>
            Welcome to my portfolio! I'm a self-taught Front-end Developer
            passionate about creating high-quality web experiences. With my
            dedication, I'm committed to crafting digital solutions that not
            only meet your expectations but also exceed them. Explore my work to
            discover how I blend creativity and expertise to bring your ideas to
            life.
          </span>
        </div>

        <div>
          <button
            className="button n-button"
            id="contact-button"
            onClick={handleClick}
          >
            Hire me
          </button>
        </div>

        <div className="icons">
          <a
            href="https://twitter.com/ola_degen"
            target="_blank"
            rel="noopener noreferrer"
          >
            <UilTwitter className="a-icons" />
          </a>

          <a
            href="https://github.com/samflexx31"
            target="_blank"
            rel="noopener noreferrer"
          >
            <UilGithub className="a-icons" />
          </a>

          <UilLinkedin className="a-icons" />
        </div>
      </div>

      {/* right side */}
      <motion.div
        className="r-intro"
        variants={animationVariants}
        initial="hiddenRight"
        whileInView="visibleRight"
      >
        <div className="art-intro">
          <img src={Man} alt="" />
        </div>
      </motion.div>
    </div>
  );
};

export default Intro;
