import './App.css';
import About from './Component/About/About';
import { Contact } from './Component/Contact/Contact';
import Footer from './Component/Footer/Footer';
import Intro from './Component/Intro';
import Nav from './Component/Nav';
import Skills from './Component/Skills/Skills';
import Projects from './Component/Projects/Projects';

function App() {
  return (
    <div className="App">

     <Nav/>
     <Intro/>
     <About/>
     <Skills/>
     <Projects/>
     <Contact/>
     <Footer/>
    </div>
  );
}

export default App;