import React, { useState } from "react";
import "./Nav.css";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import Bar from "../Images/bars.svg";

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);

  const navItemVariants = {
    hidden: { y: -40, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { delay: 0.1 } },
  };

  const buttonVariants = {
    hidden: { scale: 0.1 },
    visible: {
      scale: 1,
      transition: { delay: 0, type: "spring", stiffness: 400, damping: 25 },
    },
  };

  const handleClick = () => {
    window.location.href = "mailto:samflex3124@gmail.com";
  };

  return (
    <div className="nav">
      {/* left side */}

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={buttonVariants}
        className="l-nav"
      >
        <span>Ola</span>
      </motion.div>

      {/* middle */}
      <div className="r-nav ">
        <motion.ul
          initial="hidden"
          whileInView="visible"
          variants={navItemVariants}
        >
          <Link spy={true} to="Navbar" smooth={true} activeClass="activeClass">
            <li>Home</li>
          </Link>
          <Link spy={true} to="About" smooth={true}>
            <li>About</li>
          </Link>
          <Link spy={true} to="Skills" smooth={true}>
            <li>Skills</li>
          </Link>
          <Link spy={true} to="Projects" smooth={true}>
            <li>Projects</li>
          </Link>
          <Link spy={true} to="Contact" smooth={true}>
            <li>Contact</li>
          </Link>
        </motion.ul>
      </div>

      {/* right */}
      <div>
        <button
          className="button n-button"
          id="contact-button"
          onClick={handleClick}
        >
          Contact me
        </button>

        {/* mobile side bar */}

        <img
          src={Bar}
          alt="bar"
          className="sidebar"
          onClick={() => setShowMenu(!showMenu)}
        />

        <div className="menu">
          <ul style={{ display: showMenu ? "flex" : "none" }}>
            <Link
              spy={true}
              to="Navbar"
              smooth={true}
              onClick={() => setShowMenu(false)}
            >
              <li>Home</li>
            </Link>
            <Link
              spy={true}
              to="About"
              smooth={true}
              onClick={() => setShowMenu(false)}
            >
              <li>About</li>
            </Link>
            <Link
              spy={true}
              to="Skills"
              smooth={true}
              onClick={() => setShowMenu(false)}
            >
              <li>Skills</li>
            </Link>
            <Link
              spy={true}
              to="Projects"
              smooth={true}
              onClick={() => setShowMenu(false)}
            >
              <li>Projects</li>
            </Link>
            <Link
              spy={true}
              to="Contact"
              smooth={true}
              onClick={() => setShowMenu(false)}
            >
              <li>Contact</li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Nav;
